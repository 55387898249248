html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}

html {
  --scrollbarBG: #0D0D0D;
  --thumbBG: #AA1327;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

main {
  display: block;
  /* background-image: url('./img/bg1.svg'), url('./img/bg2.svg');
  background-position: 14% top, 86% bottom;
  background-repeat: no-repeat, no-repeat; */
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0; 
  overflow: visible; 
}
pre {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
a {
  background-color: transparent;
  text-decoration:none;
}
abbr[title] {
  border-bottom: none; 
  text-decoration: underline; 
  text-decoration: underline dotted; 
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%; 
  line-height: 1.15; 
  margin: 0; 
}
button,
input { 
  overflow: visible;
}
textarea:focus, input:focus{
  outline: none;
}

button,
select { 
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; 
  color: inherit; 
  display: table; 
  max-width: 100%; 
  padding: 0; 
  white-space: normal; 
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; 
  padding: 0; 
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; 
  outline-offset: -2px; 
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; 
  font: inherit; 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}


@font-face {
  font-family: 'Cy Grotesk';
  src: url('fonts/cy-grotesk-grand-dark.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
}
.header-wrapper {
  position: fixed;
  width: 100%;
  padding: 24px;
  left:50%;
  transform:translateX(-50%); 
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.header__left {
  display: flex;
  align-items: center;
}
.header__logo {
  margin-right: 12px;
}
.header__light {
  background: #FFFFFD;
}
.header__dark {
  background: linear-gradient(180deg, #1C1C1C 0%, #000 100%);
}
.logo__text {
  width: 125px;
  font-family: "Cy Grotesk";
  font-size: 24px;
  line-height: 100%;
  margin-right: 64px;
}
.logo__text_dark {
  color: var(--2, var(--white, #FFF));
}
.logo__text_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.whitepaper {
  font-family: "Cy Grotesk";
  font-size: 18px;
  line-height: 32px;
  padding: 12px 32px;
  border-radius: 35px;
}
.whitepaper1 {
  font-family: "Cy Grotesk";
  font-size: 18px;
  line-height: 32px;
  padding: 12px 32px;
  border-radius: 35px;
}
.whitepaper__dark {
  color: var(--2, var(--white, #FFF));
  background: rgba(255, 255, 255, 0.15);
}
.whitepaper__light {
  color: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background: #F6ECD6;
}
.circle__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-left: 8px;
}
.circle__link1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-left: 8px;
}
.theme__switch {
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-left: 8px;
}
.theme__switch_dark {
  background: #2B272E;
}
.theme__switch_light {
  background: #F6ECD6;
}
.theme__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.theme__inner_dark {
  transition: 2s;
  background: rgba(255, 255, 255, 0.10);
}
.theme__inner_light {
  transition: 2s;
  background: #FFFFFD;
}
.connect {
  color: var(--white, #FFF);
  font-family: "Cy Grotesk";
  font-size: 18px;
  line-height: 32px;
  border-radius: 100px;
  background: var(--13443, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  padding: 12px 32px;
  border: 0;
  cursor: pointer;
}
@media (max-width: 1017px) {
  .header-wrapper {
    padding: 12px;
  }
  .logo__text {
    width: 95px;
    font-size: 18px;
    margin-right: 16px;
  }
  .circle__link {
    display: none;
  }
}
@media (max-width: 773px) {
  .whitepaper {
    display: none;
  }
  .logo__text {
    margin-right: 8px;
  }
}
@media (max-width: 551px) {
  .connect {
    font-size: 12px;
    line-height: 32px;
    padding: 6px 8px;
    line-height: 17px;
    margin-left: 8px;   
  }
  .logo__text {
    width: 75px;
    font-size: 14px;
    margin-right: 8px;
  }


  .theme__switch {
    width: 48px;
    height: 48px;
  }
  .theme__inner {
    width: 40px;
    height: 40px;
  }
}
.bubble-wrapper {
  padding-top: 104px;
}
.bubble-wrapper_dark {
  background: #000;
  background-image: url('./img/leftblur.png'), url('./img/circles.png');
  background-position: left bottom, right center;
  background-repeat: no-repeat, no-repeat;
}
.bubble-wrapper_light {
  background: #F5F8DF;
  background-image: url('./img/leftblur.png'), url('./img/circles.svg');
  background-position: left bottom, right center;
  background-repeat: no-repeat, no-repeat;
}
.bubble {
  padding: 90px 15px 60px 15px;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.bubble__img {
  border-radius: 50%;
  width: 610px;
  height: 610px;
  background-image: url('./img/bubble.gif');
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(15px);
}
.bubble__right {
}
.bubble__blur {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  background: transparent;
}
.bubble__left {
  max-width: 708px;
  width: 100%;
}
.bubble__takeaway {
  width: 100%;
  font-family: "Cy Grotesk";
  font-size: 24px;
  line-height: 48px; 
  margin-bottom: 24px;
}
.bubble__takeaway_dark {
  color: var(--white, #FFF);
}
.bubble__takeaway_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bubble__banksize {
  font-family: "Cy Grotesk";
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 24px;
}
.banksize__total {
  background: var(--04, linear-gradient(133deg, #FFBA09 24.47%, #FF17E8 109.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banksize__total_dark {
  color: rgba(255, 255, 255, 0.30);
}
.banksize__total_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  border-radius: 100px;
  height: 33px;
  margin-bottom: 48px;
}
.line_dark {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 51.5%, rgba(255, 255, 255, 0.20) 100%), #000;
}
.line_light {
  background: linear-gradient(180deg, rgba(87, 149, 10, 0.20) 0%, rgba(71, 122, 8, 0.00) 51.5%, rgba(82, 142, 8, 0.20) 100%), #F5F8DF;
}
.line__inner {
  border-radius: 100px;
  height: 33px;
  background: var(--04, linear-gradient(133deg, #FFBA09 24.47%, #FF17E8 109.92%));
}
.claim {
  border-radius: 80px;
  background: var(--01, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  border: 0;
  color: var(--white, #FFF);
  font-family: "Cy Grotesk";
  font-size: 24px;
  line-height: 48px; /* 200% */
  width: 100%;
  height: 80px;
  cursor: pointer;
  margin-bottom: 64px;
}
.ref {
  font-family: "Cy Grotesk";
  border-radius: 80px;
  border: 0;
  font-size: 24px;
  line-height: 36px; /* 200% */
  font-weight: 700;
  width: 100%;
  height: 60px;
  cursor: pointer;
  margin-top: 18px;
}
.ref_dark {
  color: var(--2, var(--white, #FFF));
  background: rgba(255, 255, 255, 0.15);
}
.ref_light {
  color: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background: #F6ECD6;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.title__circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.title__circle_dark {
  border-bottom: 2px solid var(--02, #08B256);
}
.title__circle_light {
  border-bottom: 2px solid var(--01, #D63535);
}
.title__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
}
.title__text_dark {
  color: var(--white, #FFF);
}
.title__text_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.referral__input {
  width: 100%;
  border-radius: 100px;
  padding: 12px 12px 12px 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  margin-bottom: 24px;
}
.referral__input_dark {
  border-bottom: 2px solid var(--white, #FFF);
  background: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.3);
}
.referral__input_light {
  border-bottom: 2px solid #FBAA8D;
  background: var(--white, #FFF);
  color: rgba(0, 0, 0, 0.3);
}


.referral__div {
  width: 100%;
  border-radius: 100px;
  padding: 12px 12px 12px 32px;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  margin-bottom: 24px;
}
.referral__div_dark {
  border-bottom: 2px solid var(--02, #08B256);
  background: rgba(255, 255, 255, 0.15);
}
.referral__div_light {
  border-bottom: 2px solid var(--01, #D63535);
  background: var(--white, #FFF);
}



.referral__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.referral__text_dark {
  background: var(--02, linear-gradient(24deg, #08B256 17.89%, #BBEC6C 87.18%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.referral__text_light {
  background: var(--01, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titinfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.titinfo__item {
  display: grid;
	align-items: center;
  width: 32.58%;
  border-radius: 100px;
  text-align: center;
  padding: 12px 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
.titinfo__item_dark {
  color: var(--white, #FFF);
  background: rgba(255, 255, 255, 0.15);
}
.titinfo__item_light {
  background: var(--white, #FFF);
  color: #000;
}
.titinfo__bal {
  padding: 12px 32px;
  align-items: center;
  width: 32.58%;
  border-radius: 100px;
  text-align: left;
}
.titinfo__bal_dark {
  border-bottom: 2px solid #45C65E;
  background: rgba(255, 255, 255, 0.15);
}
.titinfo__bal_light {
  border-bottom: 2px solid var(--01, #D63535);
  background: var(--white, #FFF);
}
.titinfo__baltext {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.titinfo__baltext_dark {
  background: var(--02, linear-gradient(24deg, #08B256 17.89%, #BBEC6C 87.18%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.titinfo__baltext_light {
  background: var(--01, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1380px) {
  .bubble {
    padding: 20px 15px 60px 15px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .bubble__right {
    margin-bottom: 40px;
  }
}
@media (max-width: 770px) {
  .bubble__takeaway {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .bubble__banksize {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  .bubble__img {
    width: 350px;
    height: 350px;
  }
  .line {
    height: 24px;
    margin-bottom: 24px;
  }
  .line__inner {
    height: 24px;
  }
  .claim {
    font-size: 18px;
    line-height: 36px; /* 200% */
    width: 100%;
    height: 50px;
    margin-bottom: 24px;
  }
  .ref {
    font-size: 18px;
    height: 50px;
    margin-top: 24px;
  }
  .title__text {
    font-size: 14px;
  }

  .referral__input {
    padding: 6px 6px 6px 16px;
    font-size: 12px;
    margin-bottom: 16px;
  }

  .referral__div {
    padding: 6px 6px 6px 16px;
    margin-bottom: 16px;
  }
  .referral__text {
    font-size: 12px;
  }

  .titinfo__item {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 14px;
  }

  .titinfo__bal {
    padding: 8px 16px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .titinfo__baltext {
    font-size: 12px;
    line-height: 14px;
  }
}
.stats-wrapper_dark {
  background: linear-gradient(180deg, #1C1C1C 0%, #000 100%);
}
.stats-wrapper_light {
  background: linear-gradient(180deg, #FFF 0%, #F5F8DF 100%);
}
.stats {
  padding: 48px 15px 196px 15px;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.stat {
  max-width: 730px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.stat__pic {
  width: 120px;
  height: 120px;
}
.info {
  max-width: 578px;
  width: 100%;
}
.info__title {
  width: 100%;
  font-family: "Cy Grotesk";
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 32px;
}
.info__title_dark {
  color: var(--white, #FFF);
}
.info__title_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stat__line {
  width: 100%;
  display: grid;
  grid-template-columns: 19% auto 37.2%;
  gap: 8px;
  margin-bottom: 8px;
}
.stat__item {
  border-radius: 100px;
  padding: 12px 24px;
}
.stat__level {
  display: flex;
}
.stat__level_none {
  display: flex;
  justify-content: center;
}
.stat__ok {
  margin-right: 10px;
}
.stat__item_dark {
  background: rgba(255, 255, 255, 0.15);
}
.stat__item_light {
  background: #F6ECD6;
}
.tit__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
.tit__text_dark {
  color: var(--white, #FFF);
}
.tit__text_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.item__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.active__dark {
  background: var(--02, linear-gradient(24deg, #08B256 17.89%, #BBEC6C 87.18%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.active__light {
  background: var(--01, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.none__dark {
  color: var(--white, #FFF);
}
.none__light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.boost {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  max-width: 578px;
  padding: 8px 48px 8px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  border-bottom: 2px solid var(--01, #D63535);
}
.boost__button {
  border: 0;
  cursor: pointer;
  border-radius: 100px;
  background: var(--01, linear-gradient(104deg, #D63535 11.4%, #D256B2 51.66%, #3D43DE 96.42%));
  color: var(--white, #FFF);
  font-family: "Cy Grotesk";
  font-size: 24px;
  line-height: 48px;
  padding: 16px 48px;
}
.boost_dark {
  background: #201D1B;
}
.boost_light {
  background: var(--white, #FFF);
}
.boost__text {
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px; /* 200% */
}
.boost__text_dark {
  color: var(--White, var(--white, #FFF));
}
.boost__text_light {
  background: var(--Dark-01, linear-gradient(92deg, #000 5.53%, #3F285E 97.6%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1499px) {
  .stat {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .info__title {
    margin-top: 20px;
  }
}
@media (max-width: 1215px) {
  .stats {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 773px) {
  .info__title {
    font-size: 25px;
    line-height: 36px;
    margin-bottom: 24px;
  }
  .tit__text {
    font-size: 14px;
    line-height: 18px;
  }
  .item__text {
    font-size: 14px;
    line-height: 24px;
  }
  .stat__item {
    padding: 8px 18px;
  }
  .boost__text {
    font-size: 14px;
    line-height: 36px; /* 200% */
  }
  .boost__button {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 24px;
  }
  .boost {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 8px 20px 8px 8px;
  }
  .stats {
    padding: 20px 10px 40px 10px;
  }
}
.footer {
  border-radius: 32px 32px 0px 0px;
  margin: -182px auto 0 auto;
  max-width: 1520px;
  padding: 48px 64px 64px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__right {
  display: flex;
}
.footer__left {
  display: flex;
  align-items: center;
}
@media (max-width: 773px) {
  .stat__level {
    justify-content: center;
  }
  .footer__left {
    display: none;
  }
  .footer {
    padding: 16px 8px 16px 8px;
    margin: -30px auto 0 auto;
  }
}